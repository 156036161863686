import "./App.css";

function countWorkingDays(startDate, endDate) {
  let workingDays = 0;
  let currentDate = new Date(startDate.getTime());

  while (currentDate <= endDate) {
    const day = currentDate.getDay();

    if (day !== 0 && day !== 6) {
      // Not Sunday or Saturday
      workingDays++;
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return workingDays;
}

function App() {
  const today = new Date();
  const endDate = new Date(2024, 6, 31); // July 31st, 2024
  const workingDays = countWorkingDays(today, endDate);

  return (
    <div className="App">
      <span className="days">{workingDays}</span>
      <span className="left">days left</span>
    </div>
  );
}

export default App;
